import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [2];

export const dictionary = {
		"/": [5],
		"/app/admin": [6,[2,4],[3]],
		"/app/admin/insurance": [~7,[2,4],[3]],
		"/app/admin/insurance/addl": [~8,[2,4],[3]],
		"/app/admin/tinting": [~9,[2,4],[3]],
		"/app/insurance/new/[car_id=int]": [~10,[2],[3]],
		"/app/login": [11,[2],[3]],
		"/app/my-gov-demo": [~12,[2],[3]],
		"/app/my-gov-demo/login": [~13,[2],[3]],
		"/app/tinting/new/[car_id=int]": [14,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';